.root {
	height: 100%;
	display: flex;
	flex-direction: column;

	& .header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		& .info {
			display: flex;
			gap: var(--space-2xs);

			@media (--md) {
				gap: var(--space-xs);
			}
		}
	}

	& .item {
		--border-color: var(--primary-greige);

		border-bottom: 1px solid var(--border-color);

		@media (--md) {
			padding-block: 10px;
		}

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}

		& .link {
			text-decoration: none;
			color: inherit;

			& .content {
				position: relative;
				padding: 10px;
				display: flex;
				gap: var(--space-sm);
				border-radius: 8px;
				border: 1px solid transparent;
				transition: var(--transition-standard);

				@media (--md) {
					gap: var(--space-md);
				}

				& img {
					border-radius: 4px;
				}

				@media (hover: hover) {
					&:hover {
						border: 1px solid var(--primary-dark-blue-005);
						background-color: var(--primary-dark-blue-005);
					}
				}

				& .info {
					display: flex;
					flex-direction: column;
					justify-content: center;

					& > :last-child {
						margin-top: var(--space-2xs);
					}
				}
			}
		}
	}

	& .clear {
		margin-top: var(--space-2xs);
		cursor: pointer;
	}

	.remove {
		--size: 32px;

		width: var(--size);
		height: var(--size);
		position: absolute;
		top: 0;
		right: 15px;
		transform: translateX(-50%);
		margin-block: var(--space-2xs);
		margin-inline: var(--space-sm);

		@media (--md) {
			margin: var(--space-xs);
		}
	}
}

.introWrapper {
	padding-block: var(--space-xl) var(--space-sm);
	display: flex;
	align-items: center;
	justify-content: center;

	@media (--md) {
		padding-block: 90px;
	}

	& .introHeader {
		width: 100%;
		padding-inline: var(--space-sm);
		padding-top: var(--space-2xs);
		display: flex;
		align-items: center;
		gap: 8px;

		@media (--md) {
			padding: 0;
			gap: var(--space-sm);
		}
	}
}

.products {
	display: flex;
	flex-direction: column;
	gap: var(--space-sm);

	@media (--md) {
		gap: var(--space-lg);
	}
}

.title {
	@extend %typo-utility-4;

	font-weight: 500;
	margin-bottom: var(--space-xs);

	@media (--md) {
		@extend %typo-utility-2;
	}
}

.variant {
	@extend %typo-utility-5;

	opacity: 0.5;
	margin-bottom: var(--space-sm);

	@media (--md) {
		@extend %typo-utility-3;
	}
}

.price {
	@extend %typo-utility-5;

	@media (--md) {
		@extend %typo-utility-3;
	}
}

.priceBeforeDiscount {
	color: var(--primary-dark-blue-04);
	text-decoration: line-through;
	margin-left: var(--space-2xs);

	@media (--lg) {
		margin-left: var(--space-xs);
	}
}

.items {
	@media (--md) {
		padding: 0 30px;
	}
}
